export const environment = {
  production: true,
  baseURL: "https://api.rodoviasolucoes.com.br/CJM/", // Java
  baseURLChat: "https://api.rodoviasolucoes.com.br/CJM/", // Openfire
  credentialsURL: 'https://api.rodoviasolucoes.com.br/CJM/App/v2/web/chat/credentials/', // Openfire
  baseHref: "/",
  baseAspCoreURL: 'https://api.rodoviasolucoes.com.br/dashboard/api/v1', // .Net6
  baseWebUrl: 'http://localhost:4200',
  baseWebUrlV2: 'https://d3ngs1ybei3jyp.cloudfront.net/',
  baseURlDashboardRodovia: 'https://web-hmg.rodoviasolucoes.com.br/dashboardrodovia',
  baseUrlNovaDashboard: 'https://api.rodoviasolucoes.com.br/dashboardviam/',
  baseURLMonitoramento: 'https://web-hmg.rodoviasolucoes.com.br/telemetria/',
  firebaseConfig: {
    apiKey: "AIzaSyCRwyAfpjjRyIZwNmU4lvk4FzqZfzOdC8I",
    authDomain: "rodoviaviam-5cf5a.firebaseapp.com",
    databaseURL: "https://rodoviaviam-5cf5a-default-rtdb.firebaseio.com",
    projectId: "rodoviaviam-5cf5a",
    storageBucket: "rodoviaviam-5cf5a.appspot.com",
    messagingSenderId: "365158190068",
    appId: "1:365158190068:web:fe4df9df79c9a3ffba07a1",
    measurementId: "G-FBY7SYEH73"
  },
  firebaseRootNode: '' //deixar vazio para produção - Homologação 'homol' - Desenvolvimento 'dev'
};

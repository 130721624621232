import { DetalhesInconformidadesComponent } from './components/reports/detalhes-inconformidades/detalhes-inconformidades.component';
import { DetalhesInconformidadesViamComponent } from './components/reports/detalhes-inconformidades-viam/detalhes-inconformidades-viam.component';
// "interface"
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatSelectModule,
  MatExpansionModule,
  MatTooltipModule,
  MatTabsModule,
  MatInputModule,
  MatSlideToggleModule,
  MatSortModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCardModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatToolbar,
  MatToolbarModule,
  MatMenuModule
} from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// "infra"
import { HttpModule } from "@angular/http";
import { AppRoutingModule } from "./app-routing.module";
import { } from '@angular/cdk/keycodes';
// "Maps"
import { AgmCoreModule } from "@agm/core";
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmOverlays } from 'agm-overlays';

import { NgxSpinnerModule } from 'ngx-spinner';

// "services"
import { AuthGuard } from "./guards/auth.guard";
import { BranchService } from "./services/crud/branch.service";
import { CityService } from "./services/crud/city.service";
import { CompanyService } from "./services/crud/company.service";
import { LoginService } from "./services/login.service";
import { UserService } from "./services/crud/user.service";
import { SascarEventsService } from "./services/crud/sascar-events.service";
import { WebserverService } from "./services/crud/sascar-webserver.service";
import { SascarConfigService } from "./services/crud/sascar-config.service";
import { PortariaService } from "./services/crud/portaria.service";

// "interface"
import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { LoginComponent } from "./components/login/login.component";

import { HeaderComponent } from "./components/base/app-header/app-header.component";
import { AppNavComponent } from "./components/base/app-nav/app-nav.component";
import { BaseContainerComponent } from "./components/base/base-container/base-container.component";

import { MainComponent } from "./components/main/main.component";

import { CompanyListComponent } from "./components/crud/company/list/list.component";
import { CompanyFormComponent } from "./components/crud/company/form/form.component";
import { UserListComponent } from "./components/crud/user/list/list.component";
import { UserFormComponent } from "./components/crud/user/form/form.component";
import { ContextPickerComponent } from "./components/context-picker/context-picker.component";

import { OrderModule } from "ngx-order-pipe";
import { DailyPanelComponent } from "./components/daily-panel/panel.component";
import { JourneyStateService } from "./services/crud/journey-state.service";
import { EventsService } from "./services/crud/events.service";
import { JourneyService } from "./services/crud/journey.service";
import { BranchListComponent } from "./components/crud/branch/list/list.component";
import { BranchFormComponent } from "./components/crud/branch/form/form.component";
import { VehicleListComponent } from "./components/crud/vehicle/list/list.component";
import { VehicleFormComponent } from "./components/crud/vehicle/form/form.component";
import { VehicleService } from "./services/crud/vehicle.service";
import { VehicleTypeService } from "./services/crud/vehicle-type.service";
import { LoadingUnloadingPointsFormComponent } from "./components/crud/loading-unloading-point/form/form.component";
import { LoadingUnloadingPointListComponent } from "./components/crud/loading-unloading-point/list/list.component";
import { LoadingUnloadingPointsService } from "./services/crud/loading-unloading-points.service";
import { ToleranceService } from "./services/crud/tolerance.service";
import { ToleranceFormComponent } from "./components/crud/tolerance/form/form.component";
import { ToleranceListComponent } from "./components/crud/tolerance/list/list.component";
import { HolidayFormComponent } from "./components/crud/holiday/form/form.component";
import { HolidayListComponent } from "./components/crud/holiday/list/list.component";
import { HolidayService } from "./services/crud/holiday.service";
import { HolidayScopeService } from "./services/crud/holiday-scope.service";
import { UtilService } from "./services/utils.service";
import { FederativeUnitService } from "./services/crud/federative-unit.service";
import { DateTimePickerInputComponent } from "./components/base/datetimepicker-input/datetimepicker-input.component";
import { TaxAndInspectBarrierFormComponent } from "./components/crud/tax-and-inspect-barrier/form/form.component";
import { TaxAndInspectBarrierListComponent } from "./components/crud/tax-and-inspect-barrier/list/list.component";
import { TaxAndInspectBarrierService } from "./services/crud/tax-and-inspect-barrier.service";
import { VersionPanelComponent } from "./components/version-panel/version.component";
import { VacationService } from "./services/crud/vacation.service";
import { SascarEventsComponent } from "./components/crud/sascar-events/sascar-events.component";
import { SascarWebserverComponent } from "./components/crud/sascar-webserver/sascar-webserver.component";
import { SascarConfigComponent } from "./components/crud/sascar-config/sascar-config.component";

// Locale
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData, APP_BASE_HREF } from '@angular/common';
import { DriverService } from './services/crud/driver.service';
import { AutoCompleteInputComponent } from './components/base/autocomplete-input/autocomplete-input.component';
import { AddressInputComponent } from './components/base/address-input/address-input.component';
import { PDFService } from './services/pdf.service';
import { JourneyListComponent } from './components/crud/journey/list/list.component';
import { DSRGridComponent } from './components/dsr-grid/dsr-grid.component';
import { DSRService } from './services/crud/dsr.service';
import { NonconformityComponent } from './components/nonconformity/nonconformity.component';
import { NonconformityService } from './services/crud/nonconformity.service';
import { ClockingReportListComponent } from './components/reports/clocking/list.component';
import { ChatService } from './services/crud/chat.service';
import { ChatDriverListComponent } from './components/reports/chat-driver/list.component';
import { JourneyLogListComponent } from './components/reports/journey-log/list.component';
import { EventsListComponent } from './components/reports/events/events.component';
import { JourneyCalculationListComponent } from './components/reports/journey-calculation/list.component';
import { JourneyCorrectionListComponent } from './components/reports/journey-correction/list.component';
import { ReportService } from './services/report/report.service';
import { ShiftService } from './services/crud/shift.service';
import { ShiftFormComponent } from './components/crud/shift/form/form.component';
import { ShiftListComponent } from './components/crud/shift/list/list.component';
import { TimePickerInputComponent } from './components/base/timepicker-input/timepicker-input.component';
import { LoadingContainerComponent } from './components/base/loading-container/loading-container.component';
import { MessageContainerComponent } from './components/base/message-container/message-container.component';
import { ColorListComponent } from './components/crud/color/list/list.component';
import { ColorVOService } from './services/crud/color-vo.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { JourneyFormComponent } from './components/crud/journey/form/form.component';
import { MiliToHHmmPipe } from './pipes/miliToHHmm/miliToHHmm.pipe';
import { BlockCopyPasteDirective } from './directives/block-copy-paste/block-copy-paste.directive';
import { ChangePasswordComponent } from './components/crud/user/change-password/change-password.component';
import { JourneyEventsService } from './services/crud/journey-events.service';
import { DriverListComponent } from './components/crud/driver/list/list.component';
import { DriverFormComponent } from './components/crud/driver/form/form.component';
import { MotiveService } from './services/crud/motive.service';
import { MotiveListComponent } from './components/crud/motive/list/list.component';
import { MotiveFormComponent } from './components/crud/motive/form/form.component';
import { VehicleTypeFormComponent } from './components/crud/vehicle-type/form/form.component';
import { VehicleTypeListComponent } from './components/crud/vehicle-type/list/list.component';
import { ParamcfgService } from './services/crud/paramcfg.service';
import { ParamcfgListComponent } from './components/crud/paramcfg/list/list.component';
import { ParamcfgFormComponent } from './components/crud/paramcfg/form/form.component';
import { BaseListComponent } from './components/base/base-list/base-list.component';
import { DynamicStripedTableComponent } from './components/base/dynamic-striped-table/dynamic-striped-table.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { SelectInputComponent } from './components/base/select-input/select-input.component';
import { TimePickerAdapter } from './components/base/timepicker-input/timepicker-input-adapter';
import { NonconformityCalendarComponent } from './components/nonconformity-calendar/nonconformity-calendar.component';
import { ExcelService } from './services/excel.service';
import { SharedModule } from './shared/shared.module';
import { PermissaoInputComponent } from './components/base/permissao-input/permissao-input.component';
import { SafeHtmlPipePipe } from './pipes/safe-html-pipe.pipe';
import { ModeloEquipamentoService } from './services/crud/modelo-equipamento.service';
import { ModeloEquipamentoListComponent } from './components/crud/modelo-equipamento/list/list.component';
import { ModeloEquipamentoFormComponent } from './components/crud/modelo-equipamento/form/form.component';
import { ModeloVeiculoFormComponent } from './components/crud/modelo-veiculo/form/form.component';
import { ModeloVeiculoListComponent } from './components/crud/modelo-veiculo/list/list.component';
import { EquipamentoService } from './services/crud/equipamento.service';
import { EquipamentoListComponent } from './components/crud/equipamento/list/list.component';
import { EquipamentoFormComponent } from './components/crud/equipamento/form/form.component';
import { LocalService } from './services/crud/local.service';
import { LocalFormComponent } from './components/crud/local/form/form.component';
import { LocalListComponent } from './components/crud/local/list/list.component';
import { VeiculoService } from './services/crud/veiculo.service';
import { VeiculoListComponent } from './components/crud/veiculo/list/list.component';
import { VeiculoFormComponent } from './components/crud/veiculo/form/form.component';
import { BaseTableComponent } from './components/base/base-table/base-table.component';
import { VeiculoTableComponent } from './components/crud/veiculo/table/table.component';
import { TomadaDeForcaComponent } from './components/reports/tomadadeforca/tomadadeforca.component';
import { CompressorCamaraFriaComponent } from './components/reports/compressor-camara-fria/compressor-camara-fria.component';
import { DisponibilidadeComponent } from './components/reports/disponibilidade/disponibilidade.component';
import { EventoComponent } from './components/reports/evento/evento.component';
import { HorimetroComponent } from './components/reports/horimetro/horimetro.component';
import { PercursoComponent } from './components/reports/percurso/percurso.component';
import { VelocidadeComponent } from './components/reports/velocidade/velocidade.component';
import { EquipamentoPortatilFormComponent } from './components/crud/equipamento-portatil/form/form.component';
import { EquipamentoPortatilListComponent } from './components/crud/equipamento-portatil/list/list.component';
import { DispositivoportatilComponent } from './components/reports/dispositivoportatil/dispositivoportatil.component';
import { DiariaMotoristaListComponent } from './components/crud/diaria-motorista/list/list.component';
import { RelatorioDiariaMotoristaComponent } from './components/reports/diaria-motorista/diaria-motorista.component';
import { OperadorListComponent } from './components/crud/operador/list/list.component';
import { OperadorFormComponent } from './components/crud/operador/form/form.component';
import { TipoFuncionarioListComponent } from './components/crud/tipo-funcionario/list/list.component';
import { TipoFuncionarioFormComponent } from './components/crud/tipo-funcionario/form/form.component';
import { MacrosListComponent } from './components/crud/macros/list/list.component';
import { MacrosFormComponent } from './components/crud/macros/form/form.component';
import { HodometroFormComponent } from './components/crud/hodometro/form/form.component';
import { PresencaEquipamentoComponent } from './components/reports/presenca-equipamento/presenca-equipamento.component';
import { TransporteEquipamentoComponent } from './components/reports/transporte-equipamento/transporte-equipamento.component';
import { GraficoProducaoEquipamentoComponent } from './components/reports/grafico-producao-equipamento/grafico-producao-equipamento.component';
import { ControleCombustivelFormComponent } from './components/crud/controle-combustivel/form/form.component';
import { UsoBetoneiraComponent } from './components/reports/uso-betoneira/uso-betoneira.component';
import { FaturamentoComponent } from './components/faturamento/faturamento.component';
import { ViagemFormComponent } from './components/crud/viagem/form/form.component';
import { SascarVeiculoListComponent } from './components/crud/sascar-veiculos/list/list.component';
import { SascarVeiculoFormComponent } from './components/crud/sascar-veiculos/form/form.component';
import { InconformidadeListComponent } from './components/crud/inconformidade/list/list.component';
import { InconformidadeFormComponent } from './components/crud/inconformidade/form/form.component';
import { ContratosComponent } from './components/administrativo/contratos/contratos.component';
import { RotaListComponent } from './components/rotas/list/list.component';
import { RotaFormComponent } from './components/rotas/form/form.component';
import { HttpClientModule } from '@angular/common/http';
import { NumeroViagemComponent } from './components/reports/numero-viagem/numero-viagem.component';
import { EstimativaProducaoComponent } from './components/reports/estimativa-producao/estimativa-producao.component';
import { GraficoVelocidadeComponent } from './components/reports/grafico-velocidade/grafico-velocidade.component';
import { TipoRankingListComponent } from './components/crud/tipo-ranking/list/list.component';
import { TipoRankingFormComponent } from './components/crud/tipo-ranking/form/form.component';
import { RankingMotoristaComponent } from './components/ranking-motorista/ranking-motorista.component';
import { PontuacaoAvancadaComponent } from './components/reports/pontuacao-avancada/pontuacao-avancada.component';
import { TempoFaixaComponent } from './components/reports/tempo-faixa/tempo-faixa.component';
import { DetalheTelemetriaComponent } from './components/reports/detalhe-telemetria/detalhe-telemetria.component';
import { TempoTransporteGraficoComponent } from './components/reports/tempo-transporte-grafico/tempo-transporte-grafico.component';
import { TempoCarregamentoGraficoComponent } from './components/reports/tempo-carregamento-grafico/tempo-carregamento-grafico.component';
import { SensorPortaComponent } from './components/reports/sensor-porta/sensor-porta.component';
import { ApuracaoComponent } from './components/reports/apuracao/apuracao.component';
import { MonitoramentoComponent } from './components/monitoramento/monitoramento.component';
import { TempoReferenciaComponent } from './components/reports/tempo-referencia/tempo-referencia.component';
import { TempoReferenciaDetalhesComponent } from './components/reports/tempo-referencia-detalhes/tempo-referencia-detalhes.component';
import { PercursoDetalhadoComponent } from './components/reports/percurso-detalhado/percurso-detalhado.component';
import { SensoresDetalhadoComponent } from './components/reports/sensores-detalhado/sensores-detalhado.component';
import { EventoMotoristaComponent } from './components/reports/evento-motorista/evento-motorista.component';
import { IframeDirective } from './components/monitoramento/iframe.directive';
import { FiltroGrupoELocaisComponent } from './shared/components/filtro-grupos-e-locais/filtro-grupos-e-locais.component';
import { GrupoLocaisFormComponent } from './components/crud/grupo-locais/form/form.component';
import { GrupoLocaisListComponent } from './components/crud/grupo-locais/list/list.component';
import { Contadoresindicadores } from './shared/components/contadores-indicadores/contadores-indicadores';
import { RankingLocais } from './shared/components/ranking-locais/ranking-locais';
import { OcorrenciasCargaDescarga } from './shared/components/ocorrencias-carga-descarga/ocorrencias-carga-descarga';
import { SensorTemperatura } from './shared/components/sensor-temperatura/sensor-temperatura';
import { ConvertToHourPipe } from './pipes/convert-to-hour/convert-to-hour';
import { DetalhesIndicadoresLocaisComponent } from './shared/components/detalhes-indicadores-locais/detalhes-indicadores-locais';
import { DetalhesAcionamentosLocaisComponent } from './shared/components/detalhes-acionamentos-locais/detalhes-acionamentos-locais';
// tslint:disable-next-line:max-line-length
import { DetalhesAberturaBauForaCercaComponent } from './shared/components/detalhes-abertura-bau-fora-cerca/detalhes-abertura-bau-fora-cerca';
import { ContadoresIndicadoresTelemetria } from './shared/components/contadores-indicadores-telemetria/contadores-indicadores-telemetria';
import { DetalhesEficienciaVeiculosComponent } from './shared/components/detalhes-eficiencia-veiculos/detalhes-eficiencia-veiculos';
import { QuadroCombustivelComponent } from './shared/components/quadro-combustivel/quadro-combustivel-component';
import { QuadroEficienciaComponent } from './shared/components/quadro-eficiencia/quadro-eficiencia.component';
import { ListaDeDetalhesEficienciaComponent } from './shared/components/lista-detalhes-eficiencia/lista-detalhes-eficiencia-component';
import { QuadroVazioDoisComponent } from './shared/components/quadro-vazio2/quadro-vazio2-component';
import { QuadroVeiculosComponent } from './shared/components/quadro-veiculos/quadro-veiculos-component';
import { FiltroTelemetriaComponent } from './shared/components/filtro-telemetria/filtro-telemetria-component';
import { JornadaMotoristaComponent } from './components/jornada-motorista/jornada-motorista.component';
import { FiltroSituacaoEMotoristaComponent } from './shared/components/filtro-situacao-e-motorista/filtro-situacao-e-motorista.component';
// tslint:disable-next-line:max-line-length
import { ContadoresJornadaMotoristaComponent } from './shared/components/contadores-jornada-motorista/contadores-jornada-motorista.component';
import { ListaMotoristasComponent } from './shared/components/lista-motoristas/lista-motoristas.component';
import { MonitoramentoJornadaComponent } from './components/monitoramento-jornada/monitoramento-jornada.component';
// tslint:disable-next-line:max-line-length
import { FiltroMonitoramentoJornadaComponent } from './shared/components/filtro-monitoramento-jornada/filtro-monitoramento-jornada.component';
// tslint:disable-next-line:max-line-length
import { ContadoresMonitoramentoJornadaComponent } from './shared/components/contadores-monitoramento-jornada/contadores-monitoramento-jornada.component';
// tslint:disable-next-line:max-line-length
import { DetalhesMonitoramentoJornadaAusenteComponent } from './shared/components/detalhes-monitoramento-jornada-ausente/detalhes-monitoramento-jornada-ausente.component';
// tslint:disable-next-line:max-line-length
import { DetalhesMonitoramentoJornadaDentroJornadaComponent } from './shared/components/detalhes-monitoramento-jornada-dentro-jornada/detalhes-monitoramento-jornada-dentro-jornada.component';
// tslint:disable-next-line:max-line-length
import { DetalhesListaCompletaMotoristaComponent } from './shared/components/detalhes-lista-completa-motorista/detalhes-lista-completa-motorista.component';
// tslint:disable-next-line:max-line-length
import { DetalhesMonitoramentoJornadaExtrapoladaComponent } from './shared/components/detalhes-monitoramento-jornada-extrapolada/detalhes-monitoramento-jornada-extrapolada.component';
// tslint:disable-next-line:max-line-length
import { DetalhesMonitoramentoJornadaParadaComponent } from './shared/components/detalhes-monitoramento-jornada-parada/detalhes-monitoramento-jornada-parada.component';
import { AnalisesJornadaEmAbertoComponent } from './shared/components/analises-jornada-em-aberto/analises-jornada-em-aberto.component';
import { IntercorrenciasMotoristasComponent } from './shared/components/intercorrencias-motoristas/intercorrencias-motoristas.component';
import { HorasOperacaoMotoristasComponent } from './shared/components/horas-operacao-motoristas/horas-operacao-motoristas.component';
import { IdentificacaoOperadorListComponent } from './components/crud/identificacaooperador/list/list.component';
import { IdentificacaoOperadorFormComponent } from './components/crud/identificacaooperador/form/form.component';
import { PontosPercorridosComponent } from './components/reports/pontos-percorridos/pontos-percorridos.component';
import { ProdutividadeVeiculoComponent } from './components/reports/produtividade-veiculo/produtividade-veiculo.component';
import { ProdutividadeMotoristaAdmComponent } from './components/reports/produtividade-motorista-adm/produtividade-motorista-adm.component';
import { MapaEficienciaVeiculoComponent } from './shared/components/mapa-eficiencia-veiculos/mapa-eficiencia-veiculos';
import { MapaLocalizacaoVeiculoComponent } from './shared/components/mapa-localizacao-veiculo/mapa-localizacao-veiculo';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateMomentParserFormatter } from '../app/shared/models/calendar-brasilian-config';
import { MatDividerModule } from '@angular/material/divider';

import { DetalharJornadaMotoristaComponent } from './components/detalhar-jornada-motorista/detalhar-jornada-motorista.component';
import { NewContextPickerComponent } from './components/new-context-picker/new-context-picker.component';
import { DailyPanelDashboardComponent } from './components/daily-panel-dashboard/panel-dashboard.component';
import { HighchartsService } from './services/highcharts.service';
import { DashboardHomeService } from './services/dashboard/dashboard.service';
import { DashboardMapFilterComponent } from './components/dashboard-map-filter/dashboard-map-filter.component';
import { MapDetailsComponent } from './components/map-details/map-details.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MapMotoristaDetailsComponent } from './components/map-motorista-details/map-motorista-details.component';
import { MapEquipamentoDetailsComponent } from './components/map-equipamento-details/map-equipamento-details.component';
import { TimeMaskDirective } from './directives/time-mask.directive';
import { ValidadorService } from './services/validador.service';
import { LoadingComponent } from './loader/loader.component';
import { MatPaginatorIntlBrasil } from './pipes/customClass';
import { MapDetailsTrajetoComponent } from './components/map-details/map-details-trajeto.component';
import { MapDetailsInconformidadesComponent } from './components/map-details/map-details-inconformidades.component';
import { MapDetailsAlertasComponent } from './components/map-details/map-details-alertas.component';
import { TelaIndicadorGestaoComponent } from './components/indicadores/gestao/tela-indicador-gestao.component';
import { TelaIndicadoresComponent } from './components/indicadores/tela-indicadores.component';
import { TelaIndicadoresBauComponent } from './components/indicadores/bau/tela-indicadores-bau.component';
import { TelaIndicadorTelemetriaComponent } from './components/indicadores/telemetria/tela-indicador-telemetria.component';
import { ModalConfigurarPainelDashboardComponent } from './shared/components/modal-configurar-painel-dashboard/modal-configurar-painel-dashboard.component';
import { TempoPeriodoParadoComponent } from "./components/reports/tempo-periodo-parado/tempo-periodo-parado.component";
import { ModalTempoPeriodoParadoComponent } from "./components/reports/tempo-periodo-parado/modal-tempo-periodo-parado.component";
import { RegistroAlteracoesComponent } from "./components/reports/registro-alteracoes/registro-alteracoes.component";
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from 'ngx-mat-datetime-picker';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MetasListComponent } from './components/crud/metas/list/list.component';
import { MetasFormComponent } from './components/crud/metas/form/form.component';
import { DetalhesDoIndicadorComponent } from "./shared/components/detalhes-do-indicador/detalhes-do-indicador.component";
import { AddSecondsToDatePipe } from "./pipes/add-seconds-to-date/add-seconds-to-date";
import { QuadroVazioUmComponent } from "./shared/components/quadro-vazio1/quadro-vazio1-component";
import { MetasService } from "./services/crud/meta.service";
import { TurnoEscalaListComponent } from './components/crud/turno-escala/list/list.component';
import { TurnoEscalaFormComponent } from './components/crud/turno-escala/form/form.component';
import { TurnoEscalaService} from './services/crud/turno-escala.service';
import { ExportacaoGradeJornadaComponent } from "./components/reports/exportacao-grade-jornadas/exportacao-grade-jornada";
import { ModalRegistroAlteracoes } from "./components/reports/registro-alteracoes/modal-registro-alteracoes.component";
import { DashVersoesComponent } from "./components/dash-versoes/dash-versoes.component";
import { TempoDirecaoComponent } from './components/reports/tempo-direcao/tempo-direcao.component';
import { ModalAberturaBauForaLocalCadastradoComponent } from './components/indicadores/gestao/modal-abertura-bau-fora-local-cadastrado-component';
import { ModalTodosLocaisPeriodoComponent } from './components/indicadores/gestao/modal-todos-locais-periodo-component';
import { ModalDetalhesLocalComponent } from './components/indicadores/gestao/modal-detalhes-local-component';
import { ModalDetalhesOcorrenciaComponent } from './components/indicadores/gestao/modal-detalhes-ocorrencia-component';
import { DetalhesPercursoVelocidadeComponent } from './components/reports/detalhes-percurso-velocidade/detalhes-percurso-velocidade.component';
import { DetalhesRastreadorPortatilComponent } from './components/reports/detalhes-rastreador-portatil/detalhes-rastreador-portatil.component';
import { RelatorioIntegracaoSllic } from './components/reports/integracao-sllic/integracao-sllic.component';
import { EventDetailsComponent } from './components/event/event-details.component';
import { HoursTotalizerComponent } from './components/reports/hour-totalizer/list.component';
import { ObservacoesIncoformidadesComponent } from './components/observacoes-inconformidades/observacoes-inconformidades.component';
import { ConsultaLocaisPercorridosComponent } from './components/reports/consulta-locais-percorridos/consulta-locais-percorridos.component';
import { Portaria671FormComponent } from './components/crud/portarias/671/form/form.component';
import { EspelhoPontoCompoenent } from './components/reports/espelho-ponto/espelho-ponto.component';
import {ArquivoAeJComponent} from './components/reports/arquivo-aej/arquivo-aej.component'
import { ChatFireBaseComponent } from './shared/chat-firebase/chat-firebase.component';
import { DayOfWeekPipe } from './pipes/day-of-week/day-of-week.pipe';
import { ArquivoAfdComponent } from './components/reports/arquivo-afd/arquivo-afd.component';
import { PainelIndicadoresComponent } from './components/painel-indicadores/painel-indicadores.component';
import { DynamicGradientDirective } from './components/painel-indicadores/gradient.directive';
import { LiberacaoPontoComponent } from './components/liberacao-ponto/liberacao-ponto.component';
import { AlertaService } from './services/crud/alerta.service';
import { QuadroAlertasComponent } from './components/crud/alertas/quadro-alertas/quadro-alertas.component';
import { TitlePageComponent } from './components/title-page/title-page.component';
import { CadastroAvisoComponent } from './components/crud/alertas/cadastro-aviso/cadastro-aviso.component';
import { NgxSummernoteModule } from 'ngx-summernote'; // Importe o módulo NgxSummernoteModule
import { GestaoAvisosComponent } from './components/crud/alertas/gestao-avisos/gestao-avisos.component';
import { ModalConfirmDeleteComponent } from './components/crud/alertas/modal-confirm-delete/modal-confirm-delete.component';
import { RelatorioSituacaoVeiculosComponent } from './components/reports/relatorio-situacao-veiculos/relatorio-situacao-veiculos.component';
import { ChatConfirmDialogComponent } from './shared/chat-firebase/confirm-dialog/chat-confirm-dialog.component';
import { NewGradesJornadasComponent } from './components/new-grades-jornadas/new-grades-jornadas.component';
import { NewProdutividadeComponent } from './components/new-produtividade/new-produtividade.component';
import { NewMarcacaoComponent } from './components/new-marcação/new-marcação.component';
import { NewDetalheInconformidadeComponent } from './components/new-detalhe-inconformidade/new-detalhe-inconformidade.component';
import { NewTotalizadoresComponent } from './components/new-totalizadores/new-totalizadores.component';
import { NewConsultaJornadaComponent } from './components/new-consulta-jornada/new-consulta-jornada.component';
import { NewCadastroEmpresaComponent } from './components/new-cadastro-empresa/new-cadastro-empresa.component';
import { NewCadastroUnidadeComponent } from './components/new-cadastro-unidade/new-cadastro-unidade.component';
import { CorrecaoEventosComponent } from './components/administrativo/correcao-eventos/correcao-eventos.component';
import { TaskLogsService } from './services/administrador/task-logs.service';
import { TaskLogsDialogComponent } from './components/administrativo/task-logs/task-logs-dialog.component';
import { TaskLogsComponent } from './components/administrativo/task-logs/task-logs.component';
import { DirecaoVersusPlacaComponent } from './components/reports/direcao-versus-placa/direcao-versus-placa.component';


// tslint:disable-next-line:max-line-length


registerLocaleData(ptBr);

@NgModule({
  declarations: [
    TimeMaskDirective,
    DashVersoesComponent,
    NewContextPickerComponent,
    AppComponent,
    LoginComponent,
    ContextPickerComponent,
    PageNotFoundComponent,
    BaseContainerComponent,
    AppNavComponent,
    MainComponent,
    HeaderComponent,
    CompanyListComponent,
    CompanyFormComponent,
    UserListComponent,
    UserFormComponent,
    DailyPanelComponent,
    BranchListComponent,
    BranchFormComponent,
    VehicleFormComponent,
    VehicleListComponent,
    LoadingUnloadingPointsFormComponent,
    LoadingUnloadingPointListComponent,
    ToleranceFormComponent,
    ToleranceListComponent,
    HolidayFormComponent,
    HolidayListComponent,
    JourneyListComponent,
    JourneyFormComponent,
    DSRGridComponent,
    DateTimePickerInputComponent,
    TimePickerInputComponent,
    AutoCompleteInputComponent,
    AddressInputComponent,
    NonconformityComponent,
    ClockingReportListComponent,
    ChatDriverListComponent,
    HoursTotalizerComponent,
    EspelhoPontoCompoenent,
    ArquivoAeJComponent,
    ArquivoAfdComponent,
    EventsListComponent,
    JourneyLogListComponent,
    JourneyCalculationListComponent,
    JourneyCorrectionListComponent,
    ShiftFormComponent,
    ShiftListComponent,
    LoadingContainerComponent,
    MessageContainerComponent,
    ColorListComponent,
    MiliToHHmmPipe,
    DayOfWeekPipe,
    ConvertToHourPipe,
    AddSecondsToDatePipe,
    BlockCopyPasteDirective,
    ChangePasswordComponent,
    TaxAndInspectBarrierFormComponent,
    TaxAndInspectBarrierListComponent,
    DriverListComponent,
    DriverFormComponent,
    MotiveListComponent,
    MotiveFormComponent,
    VehicleTypeFormComponent,
    VehicleTypeListComponent,
    ParamcfgFormComponent,
    ParamcfgListComponent,
    BaseListComponent,
    VersionPanelComponent,
    DynamicStripedTableComponent,
    SelectInputComponent,
    NonconformityCalendarComponent,
    SascarEventsComponent,
    SascarWebserverComponent,
    SascarConfigComponent,
    PermissaoInputComponent,
    SafeHtmlPipePipe,
    ModeloEquipamentoListComponent,
    ModeloEquipamentoFormComponent,
    ModeloVeiculoFormComponent,
    ModeloVeiculoListComponent,
    EquipamentoListComponent,
    EquipamentoFormComponent,
    LocalFormComponent,
    LocalListComponent,
    VeiculoListComponent,
    VeiculoFormComponent,
    BaseTableComponent,
    VeiculoTableComponent,
    TomadaDeForcaComponent,
    CompressorCamaraFriaComponent,
    DisponibilidadeComponent,
    EventoComponent,
    HorimetroComponent,
    PercursoComponent,
    VelocidadeComponent,
    EquipamentoPortatilFormComponent,
    EquipamentoPortatilListComponent,
    DispositivoportatilComponent,
    DiariaMotoristaListComponent,
    RelatorioDiariaMotoristaComponent,
    OperadorListComponent,
    OperadorFormComponent,
    TipoFuncionarioFormComponent,
    TipoFuncionarioListComponent,
    MacrosListComponent,
    MacrosFormComponent,
    HodometroFormComponent,
    PresencaEquipamentoComponent,
    TransporteEquipamentoComponent,
    GraficoProducaoEquipamentoComponent,
    ControleCombustivelFormComponent,
    UsoBetoneiraComponent,
    FaturamentoComponent,
    ViagemFormComponent,
    SascarVeiculoListComponent,
    SascarVeiculoFormComponent,
    InconformidadeListComponent,
    InconformidadeFormComponent,
    ContratosComponent,
    RotaListComponent,
    RotaFormComponent,
    NumeroViagemComponent,
    EstimativaProducaoComponent,
    GraficoVelocidadeComponent,
    TipoRankingListComponent,
    TempoPeriodoParadoComponent,
    RegistroAlteracoesComponent,
    TipoRankingFormComponent,
    RankingMotoristaComponent,
    PontuacaoAvancadaComponent,
    TempoFaixaComponent,
    DetalheTelemetriaComponent,
    DetalhesInconformidadesComponent,
    DetalhesInconformidadesViamComponent,
    TempoTransporteGraficoComponent,
    TempoCarregamentoGraficoComponent,
    SensorPortaComponent,
    ApuracaoComponent,
    MonitoramentoComponent,
    TempoReferenciaComponent,
    TempoReferenciaDetalhesComponent,
    PercursoDetalhadoComponent,
    SensoresDetalhadoComponent,
    EventoMotoristaComponent,
    IframeDirective,
    DynamicGradientDirective,
    FiltroGrupoELocaisComponent,
    GrupoLocaisFormComponent,
    GrupoLocaisListComponent,
    Contadoresindicadores,
    RankingLocais,
    OcorrenciasCargaDescarga,
    SensorTemperatura,
    DetalhesIndicadoresLocaisComponent,
    DetalhesAcionamentosLocaisComponent,
    DetalhesAberturaBauForaCercaComponent,
    TelaIndicadoresComponent,
    TelaIndicadoresBauComponent,
    TelaIndicadorTelemetriaComponent,
    TelaIndicadorGestaoComponent,
    ContadoresIndicadoresTelemetria,
    DetalhesEficienciaVeiculosComponent,
    QuadroCombustivelComponent,
    QuadroEficienciaComponent,
    ListaDeDetalhesEficienciaComponent,
    QuadroVazioDoisComponent,
    QuadroVeiculosComponent,
    FiltroTelemetriaComponent,
    MapaEficienciaVeiculoComponent,
    MapaLocalizacaoVeiculoComponent,
    FiltroTelemetriaComponent,
    IdentificacaoOperadorListComponent,
    IdentificacaoOperadorFormComponent,
    PontosPercorridosComponent,
    ProdutividadeVeiculoComponent,
    Contadoresindicadores,
    RankingLocais,
    OcorrenciasCargaDescarga,
    SensorTemperatura,
    DetalhesIndicadoresLocaisComponent,
    DetalhesAcionamentosLocaisComponent,
    JornadaMotoristaComponent,
    FiltroSituacaoEMotoristaComponent,
    ContadoresJornadaMotoristaComponent,
    ListaMotoristasComponent,
    MonitoramentoJornadaComponent,
    FiltroMonitoramentoJornadaComponent,
    ContadoresMonitoramentoJornadaComponent,
    DetalhesMonitoramentoJornadaAusenteComponent,
    DetalhesMonitoramentoJornadaDentroJornadaComponent,
    DetalhesListaCompletaMotoristaComponent,
    DetalhesMonitoramentoJornadaExtrapoladaComponent,
    DetalhesMonitoramentoJornadaParadaComponent,
    AnalisesJornadaEmAbertoComponent,
    HorasOperacaoMotoristasComponent,
    IntercorrenciasMotoristasComponent,
    ProdutividadeMotoristaAdmComponent,
    MapDetailsTrajetoComponent,
    DailyPanelDashboardComponent,
    DashboardMapFilterComponent,
    MapDetailsComponent,
    MapMotoristaDetailsComponent,
    MapEquipamentoDetailsComponent,
    DetalharJornadaMotoristaComponent,
    LoadingComponent,
    MapDetailsInconformidadesComponent,
    ModalTempoPeriodoParadoComponent,
    ModalDetalhesOcorrenciaComponent,
    ModalAberturaBauForaLocalCadastradoComponent,
    ModalTodosLocaisPeriodoComponent,
    ModalDetalhesLocalComponent,
    MapDetailsAlertasComponent,
    ModalConfigurarPainelDashboardComponent,
    MetasFormComponent,
    MetasListComponent,
    DetalhesDoIndicadorComponent,
    QuadroVazioUmComponent,
    TurnoEscalaListComponent,
    TurnoEscalaFormComponent,
    ExportacaoGradeJornadaComponent,
    ModalRegistroAlteracoes,
    TempoDirecaoComponent,
    RelatorioIntegracaoSllic,
    DetalhesPercursoVelocidadeComponent,
    DetalhesRastreadorPortatilComponent,
    EventDetailsComponent,
    ObservacoesIncoformidadesComponent,
    ConsultaLocaisPercorridosComponent,
    Portaria671FormComponent,
    ChatFireBaseComponent,
    PainelIndicadoresComponent,
    TitlePageComponent,
    GestaoAvisosComponent,
    LiberacaoPontoComponent,
    QuadroAlertasComponent,
    CadastroAvisoComponent,
    ModalConfirmDeleteComponent,
    RelatorioSituacaoVeiculosComponent,
    ChatConfirmDialogComponent,
    NewGradesJornadasComponent,
    NewProdutividadeComponent,
    NewTotalizadoresComponent,
    NewDetalheInconformidadeComponent,
    NewMarcacaoComponent,
    NewConsultaJornadaComponent,
    NewCadastroEmpresaComponent,
    NewCadastroUnidadeComponent,
    CorrecaoEventosComponent,
    TaskLogsDialogComponent,
    TaskLogsComponent,
    DirecaoVersusPlacaComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    NgbModule,
    AppRoutingModule,
    OrderModule,
    AmChartsModule,
    ColorPickerModule,
    AngularDualListBoxModule,
    SharedModule,
    ReactiveFormsModule,
    CommonModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyADPtiby33VZNn4CIveUZdb8XiE-uiazrs',
      libraries: ['drawing', 'places', 'geometry']
    }),
    BrowserAnimationsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    DataTablesModule,
    MatDividerModule,
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    MatButtonModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTableModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSortModule,
    NgSelectModule,
    NgxSpinnerModule,
    MatTableModule,
    MatTabsModule,
    MatExpansionModule,
    MatTooltipModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatSelectFilterModule,
    MatInputModule,
    MatIconModule,
    MatSlideToggleModule,
    AgmOverlays,
    AgmJsMarkerClustererModule,
    MatCardModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgxSummernoteModule,
    ReactiveFormsModule,
    MatMenuModule
  ],
  exports: [NgxSpinnerModule],
  providers: [
    AuthGuard,
    BranchService,
    LoginService,
    CompanyService,
    CityService,
    UserService,
    JourneyStateService,
    EventsService,
    JourneyService,
    VehicleService,
    VehicleTypeService,
    LoadingUnloadingPointsService,
    TaxAndInspectBarrierService,
    ToleranceService,
    HolidayService,
    HolidayScopeService,
    UtilService,
    FederativeUnitService,
    DriverService,
    PDFService,
    DSRService,
    NonconformityService,
    ChatService,
    AlertaService,
    ReportService,
    ShiftService,
    ColorVOService,
    JourneyEventsService,
    MotiveService,
    ParamcfgService,
    VacationService,
    ExcelService,
    SascarEventsService,
    WebserverService,
    SascarConfigService,
    ModeloEquipamentoService,
    EquipamentoService,
    LocalService,
    VeiculoService,
    HighchartsService,
    DashboardHomeService,
    ValidadorService,
    MetasService,
    DatePipe,
    TurnoEscalaService,
    PortariaService,
    TaskLogsService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlBrasil },
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: NgbTimeAdapter,
      useClass: TimePickerAdapter,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateMomentParserFormatter
    },
    /* {provide: APP_BASE_HREF, useValue: environment.baseHref} */
  ],
  entryComponents: [
    DetalhesIndicadoresLocaisComponent,
    DetalhesAcionamentosLocaisComponent,
    DetalhesAberturaBauForaCercaComponent,
    DetalhesEficienciaVeiculosComponent,
    MapaEficienciaVeiculoComponent,
    MapaLocalizacaoVeiculoComponent,
    DetalhesMonitoramentoJornadaAusenteComponent,
    DetalhesMonitoramentoJornadaDentroJornadaComponent,
    DetalhesListaCompletaMotoristaComponent,
    DetalhesMonitoramentoJornadaExtrapoladaComponent,
    DetalhesMonitoramentoJornadaParadaComponent,
    ModalConfigurarPainelDashboardComponent,
    ModalTempoPeriodoParadoComponent,
    ModalDetalhesOcorrenciaComponent,
    ModalAberturaBauForaLocalCadastradoComponent,
    ModalTodosLocaisPeriodoComponent,
    ModalDetalhesLocalComponent,
    DashboardMapFilterComponent,
    MapDetailsComponent,
    MapMotoristaDetailsComponent,
    MapEquipamentoDetailsComponent,
    DetalharJornadaMotoristaComponent,
    ModalConfigurarPainelDashboardComponent,
    DetalhesDoIndicadorComponent,
    EventDetailsComponent,
    ObservacoesIncoformidadesComponent,
    QuadroAlertasComponent,
    TitlePageComponent,
    CadastroAvisoComponent,
    GestaoAvisosComponent,
    ModalConfirmDeleteComponent,
    ChatConfirmDialogComponent,
    NewGradesJornadasComponent,
    NewProdutividadeComponent,
    NewTotalizadoresComponent,
    NewDetalheInconformidadeComponent,
    NewMarcacaoComponent,
    NewConsultaJornadaComponent,
    NewCadastroEmpresaComponent,
    NewCadastroUnidadeComponent,
    CorrecaoEventosComponent,
    TaskLogsDialogComponent,
    TaskLogsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from "@angular/core";
import { RESTResponseVO } from "../../classes/restresponsevo";
import { Http, Headers } from "@angular/http";
import { AuthGuard } from "../../guards/auth.guard";
import { JourneySummary } from "../../classes/journey-summary";
import { environment } from "../../../environments/environment";
import { Journey } from "../../classes/journey";
import { DriverVO } from "../../classes/driver-vo";
import { JourneyLogReport } from "../../classes/journey-log-report";
import { JourneyCalculationReport } from "../../classes/journey-calculation-report";
import { DailyPanelVO } from "../../classes/daily-panel-vo";
import { AbonarExtra } from "app/classes/abonar-extra";
import { User } from "app/classes/user";
import { Filtro } from "app/classes/filtro";

@Injectable()
export class JourneyService {

    public loggedUser: User;

    constructor(private http: Http, public authGuard: AuthGuard) {
        this.loggedUser = authGuard.loggedUser();
    }

    searchPeriod(driver: number): Promise<RESTResponseVO<any>> {
        let url: string = environment.baseURL + 'App/v2/web/jornadas/period/' + driver;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<any>;
            })
            .catch(this.handleError);
    }

    getLastJourney(company: number, branch: number): Promise<RESTResponseVO<DailyPanelVO[]>> {
        let url: string = environment.baseURL + 'App/v2/web/jornadas/ultimaJornada/' + company + '/' + branch;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<DailyPanelVO[]>;
            })
            .catch(this.handleError);
    }

    getById(id: number): Promise<RESTResponseVO<Journey>> {
        let url: string = environment.baseURL + 'App/v2/web/jornadas/' + id;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Journey>;
            })
            .catch(this.handleError);
    }

    getSummary(company: Number, branch: Number): Promise<RESTResponseVO<JourneySummary[]>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/lista/' + company + '/' + branch;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<JourneySummary[]>;
            })
            .catch(this.handleError);
    }

    getSingleSummary(id: number): Promise<RESTResponseVO<JourneySummary>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/summary/' + id;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<JourneySummary>;
            })
            .catch(this.handleError);
    }

    getIdJornada(company: number, branch: number, idUser: number): Promise<RESTResponseVO<number>> {
        var url: string = environment.baseURL + 'App/v2/web/dashboard/buscarIdJornada/' + company + '/' + branch + '/' + idUser;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<number>;
            })
            .catch(this.handleError);
    }

    mandarMostrarTodosNaDashboard(company: number, branch: number): Promise<RESTResponseVO<boolean>> {
        var url: string = environment.baseURL + 'App/v2/web/dashboard/salvarDashboard';
        var dasboard = {
            "idEmpresa": company,
            "idUnidade": branch,
            "mostrarTodosNoMapa": true
        };
        return this.http.post(url, dasboard)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<boolean>;
            })
            .catch(this.handleError);
    }

    removeDuplicates(driver: Number, start: Number, end: Number): Promise<RESTResponseVO<string>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/removeDuplicates/' + start + '/' + end + '/' + driver;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    getSummaryByDriver(driver: Number, start: Number, end: Number): Promise<RESTResponseVO<JourneySummary[]>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/lista/' + start + '/' + end + '/' + driver;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<JourneySummary[]>;
            })
            .catch(this.handleError);
    }

    retrieveDrivers(journeys: string): Promise<RESTResponseVO<DriverVO[]>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/motoristas/' + journeys;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<DriverVO[]>;
            })
            .catch(this.handleError);
    }

    closeCalculation(driver: Number, start: Number, end: Number, user: Number): Promise<RESTResponseVO<string>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/closeCalculation/' + start + '/' + end + '/' + driver + '/' + user;
        return this.http.post(url, {})
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    getJourneyCalculation(driver: Number, start: Number, end: Number, nonconformity: boolean): Promise<RESTResponseVO<JourneyCalculationReport>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/apuracaoJornada/' + start + '/' + end + '/' + driver + '/' + (nonconformity ? '1' : '0');
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<JourneyCalculationReport>;
            })
            .catch(this.handleError);
    }

    getJourneyLog(driver: Number, start: Number, end: Number): Promise<RESTResponseVO<JourneyLogReport>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/registroJornada/' + start + '/' + end + '/' + driver;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<JourneyLogReport>;
            })
            .catch(this.handleError);
    }

    fillJourney(id: number, user: number): Promise<RESTResponseVO<string>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/preencherJornada/' + id + '/' + user;
        return this.http.post(url, {})
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    lunchAllowance(id: number, user: number): Promise<RESTResponseVO<string>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/abonoAlmoco/' + id + '/' + user;
        return this.http.post(url, {})
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    markWaitingAsWorked(id: number, userId: number): Promise<RESTResponseVO<string>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/markWaitingAsWorked/' + id + '/' + userId;
        return this.http.post(url, {})
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    markDSRHE(id: number, user: number): Promise<RESTResponseVO<string>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/markDSRHE/' + id + '/' + user;
        return this.http.post(url, {})
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    marcarDiariaMotorista(id: number, user: number): Promise<RESTResponseVO<string>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/marcarDiariaMotorista/' + id + '/' + user;
        return this.http.post(url, {})
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    enviarTodoPeriodoADiaria(listaDeJornadas: any[]): Promise<RESTResponseVO<string>> {
        var h = new Headers({ 'Content-Type': 'application/json' });
        var url: string = environment.baseURL + 'App/v2/web/jornadas/enviarTodoPeriodoADiaria/';
        var json = JSON.stringify(listaDeJornadas);
        return this.http.post(url, json, { headers: h })
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
    }

    salvarJornadaManual(jornada: Journey) {
        try {
            var h = new Headers({ 'Content-Type': 'application/json' });
            var url: string = environment.baseURL + 'App/v2/web/jornadas/salvarJornadaManual/';
            var json = JSON.stringify(jornada);
            return this.http.post(url, json, { headers: h })
                .toPromise()
                .then(response => {
                    return response.json() as RESTResponseVO<Journey>;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    abonarHorasExtras(jornadaSelecionada: Journey, justificativa: String) {
        try {
            var h = new Headers({ 'Content-Type': 'application/json' });
            let abonarExtra = new AbonarExtra();
            abonarExtra.jornada = jornadaSelecionada;
            abonarExtra.justificativa = justificativa;
            abonarExtra.idUsuarioLogado = this.loggedUser.id;
            var url: string = environment.baseURL + 'App/v2/web/jornadas/abonarExtraNoDia/';
            var json = JSON.stringify(abonarExtra);
            return this.http.post(url, json, { headers: h })
                .toPromise()
                .then(response => {
                    return response.json() as RESTResponseVO<String>;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    horasExtrasCinquentaPorcento(jornadaSelecionada: Journey, justificativa: String) {
        try {
            var h = new Headers({ 'Content-Type': 'application/json' });
            let abonarExtra = new AbonarExtra();
            abonarExtra.jornada = jornadaSelecionada;
            abonarExtra.justificativa = justificativa;
            abonarExtra.idUsuarioLogado = this.loggedUser.id;
            var url: string = environment.baseURL + 'App/v2/web/jornadas/horasExtrasCinquentaPorcento/';
            var json = JSON.stringify(abonarExtra);
            return this.http.post(url, json, { headers: h })
                .toPromise()
                .then(response => {
                    return response.json() as RESTResponseVO<String>;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getTotalizerReport(filtro: any) {
        try {
            var url: string = environment.baseURL + 'App/v2/web/rel/resumo-jornadas/';
            return this.http.post(url, filtro)
                .toPromise()
                .then(response => {
                    return response.json() as RESTResponseVO<String[]>;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }


    horasExtrasDSRTurno(jornadaSelecionada: Journey, justificativa: String) {
        try {
            var h = new Headers({ 'Content-Type': 'application/json' });
            let abonarExtra = new AbonarExtra();
            abonarExtra.jornada = jornadaSelecionada;
            abonarExtra.justificativa = justificativa;
            abonarExtra.idUsuarioLogado = this.loggedUser.id;
            var url: string = environment.baseURL + 'App/v2/web/jornadas/horasExtrasDSRTurno/';
            var json = JSON.stringify(abonarExtra);
            return this.http.post(url, json, { headers: h })
                .toPromise()
                .then(response => {
                    return response.json() as RESTResponseVO<String>;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getApuracaoJornadaExcel(driver: Number, start: Number, end: Number): Promise<RESTResponseVO<string>> {
        var url: string = environment.baseURL + 'App/v2/web/jornadas/buscarApuracaoExcel/' + start + '/' + end + '/' + driver;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as string;
            })
            .catch(this.handleError);
    }


    getApuracaoJornadaRelatorio(filtro: Filtro): Promise<RESTResponseVO<string>> {
        try {
            var h = new Headers({ 'Content-Type': 'application/json' });
            var url: string = environment.baseURL + 'App/v2/web/jornadas/buscarApuracaoRelatorio/';
            var json = JSON.stringify(filtro);
            return this.http.post(url, json, { headers: h })
                .toPromise()
                .then(response => {
                    return response.json() as RESTResponseVO<String>;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getApuracaoJornadaRelatoriogerado(codigoDoRelatorio: string): Promise<RESTResponseVO<string>> {
        try {
            var h = new Headers({ 'Content-Type': 'application/json' });
            var url: string = environment.baseURL + 'App/v2/web/jornadas/buscarApuracaoRelatorioGerado';
            return this.http.get(`${url}/${codigoDoRelatorio}`)
                .toPromise()
                .then(response => {
                    return response.json() as RESTResponseVO<String>;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }


    getExcelJornadaRelatorio(listaRelExcel: any): Promise<any> {
        try {
            var h = new Headers({
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'observe': 'response'
            });
            var url: string = environment.baseURL + 'App/v2/web/jornadas/gerarExcelApuracao/';
            var json = JSON.stringify(listaRelExcel);
            return this.http.post(url, json, { headers: h })
                .toPromise()
                .then(response => {
                    return response;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }
}


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { BaseContainerComponent } from './components/base/base-container/base-container.component';
import { UserListComponent } from './components/crud/user/list/list.component';
import { UserFormComponent } from './components/crud/user/form/form.component';
import { CompanyListComponent } from './components/crud/company/list/list.component';
import { CompanyFormComponent } from './components/crud/company/form/form.component';
import { AuthGuard } from './guards/auth.guard';
import { BranchListComponent } from './components/crud/branch/list/list.component';
import { BranchFormComponent } from './components/crud/branch/form/form.component';
import { VehicleFormComponent } from './components/crud/vehicle/form/form.component';
import { LoadingUnloadingPointListComponent } from './components/crud/loading-unloading-point/list/list.component';
import { LoadingUnloadingPointsFormComponent } from './components/crud/loading-unloading-point/form/form.component';
import { ToleranceListComponent } from './components/crud/tolerance/list/list.component';
import { ToleranceFormComponent } from './components/crud/tolerance/form/form.component';
import { HolidayListComponent } from './components/crud/holiday/list/list.component';
import { HolidayFormComponent } from './components/crud/holiday/form/form.component';
import { JourneyListComponent } from './components/crud/journey/list/list.component';
import { DSRGridComponent } from './components/dsr-grid/dsr-grid.component';
import { NonconformityComponent } from './components/nonconformity/nonconformity.component';
import { ClockingReportListComponent } from './components/reports/clocking/list.component';
import { ChatDriverListComponent } from './components/reports/chat-driver/list.component';
import { EventsListComponent } from './components/reports/events/events.component';
import { JourneyLogListComponent } from './components/reports/journey-log/list.component';
import { JourneyCalculationListComponent } from './components/reports/journey-calculation/list.component';
import { JourneyCorrectionListComponent } from './components/reports/journey-correction/list.component';
import { ShiftListComponent } from './components/crud/shift/list/list.component';
import { ShiftFormComponent } from './components/crud/shift/form/form.component';
import { ColorListComponent } from './components/crud/color/list/list.component';
import { JourneyFormComponent } from './components/crud/journey/form/form.component';
import { ChangePasswordComponent } from './components/crud/user/change-password/change-password.component';
import { TaxAndInspectBarrierListComponent } from './components/crud/tax-and-inspect-barrier/list/list.component';
import { TaxAndInspectBarrierFormComponent } from './components/crud/tax-and-inspect-barrier/form/form.component';
import { DriverListComponent } from './components/crud/driver/list/list.component';
import { DriverFormComponent } from './components/crud/driver/form/form.component';
import { MotiveListComponent } from './components/crud/motive/list/list.component';
import { MotiveFormComponent } from './components/crud/motive/form/form.component';
import { VehicleTypeListComponent } from './components/crud/vehicle-type/list/list.component';
import { VehicleTypeFormComponent } from './components/crud/vehicle-type/form/form.component';
import { ParamcfgListComponent } from './components/crud/paramcfg/list/list.component';
import { ParamcfgFormComponent } from './components/crud/paramcfg/form/form.component';
import { VersionPanelComponent } from './components/version-panel/version.component';
import { NonconformityCalendarComponent } from './components/nonconformity-calendar/nonconformity-calendar.component';
import { SascarEventsComponent } from './components/crud/sascar-events/sascar-events.component';
import { SascarWebserverComponent } from './components/crud/sascar-webserver/sascar-webserver.component';
import { SascarConfigComponent } from './components/crud/sascar-config/sascar-config.component';
import { ModeloEquipamentoListComponent } from './components/crud/modelo-equipamento/list/list.component';
import { ModeloEquipamentoFormComponent } from './components/crud/modelo-equipamento/form/form.component';
import { ModeloVeiculoListComponent } from './components/crud/modelo-veiculo/list/list.component';
import { ModeloVeiculoFormComponent } from './components/crud/modelo-veiculo/form/form.component';
import { EquipamentoListComponent } from './components/crud/equipamento/list/list.component';
import { EquipamentoFormComponent } from './components/crud/equipamento/form/form.component';
import { LocalListComponent } from './components/crud/local/list/list.component';
import { LocalFormComponent } from './components/crud/local/form/form.component';
import { VeiculoListComponent } from './components/crud/veiculo/list/list.component';
import { VeiculoFormComponent } from './components/crud/veiculo/form/form.component';
import { TomadaDeForcaComponent } from './components/reports/tomadadeforca/tomadadeforca.component';
import { CompressorCamaraFriaComponent } from './components/reports/compressor-camara-fria/compressor-camara-fria.component';
import { DisponibilidadeComponent } from './components/reports/disponibilidade/disponibilidade.component';
import { EventoComponent } from './components/reports/evento/evento.component';
import { HorimetroComponent } from './components/reports/horimetro/horimetro.component';
import { PercursoComponent } from './components/reports/percurso/percurso.component';
import { VelocidadeComponent } from './components/reports/velocidade/velocidade.component';
import { EquipamentoPortatilListComponent } from './components/crud/equipamento-portatil/list/list.component';
import { EquipamentoPortatilFormComponent } from './components/crud/equipamento-portatil/form/form.component';
import { DispositivoportatilComponent } from './components/reports/dispositivoportatil/dispositivoportatil.component';
import { DiariaMotoristaListComponent } from './components/crud/diaria-motorista/list/list.component';
import { RelatorioDiariaMotoristaComponent } from './components/reports/diaria-motorista/diaria-motorista.component';
import { OperadorListComponent } from './components/crud/operador/list/list.component';
import { OperadorFormComponent } from './components/crud/operador/form/form.component';
import { TipoFuncionarioListComponent } from './components/crud/tipo-funcionario/list/list.component';
import { TipoFuncionarioFormComponent } from './components/crud/tipo-funcionario/form/form.component';
import { MacrosListComponent } from './components/crud/macros/list/list.component';
import { MacrosFormComponent } from './components/crud/macros/form/form.component';
import { HodometroFormComponent } from './components/crud/hodometro/form/form.component';
import { PresencaEquipamentoComponent } from './components/reports/presenca-equipamento/presenca-equipamento.component';
import { TransporteEquipamentoComponent } from './components/reports/transporte-equipamento/transporte-equipamento.component';
import { GraficoProducaoEquipamentoComponent } from './components/reports/grafico-producao-equipamento/grafico-producao-equipamento.component';
import { ControleCombustivelFormComponent } from './components/crud/controle-combustivel/form/form.component';
import { UsoBetoneiraComponent } from './components/reports/uso-betoneira/uso-betoneira.component';
import { FaturamentoComponent } from './components/faturamento/faturamento.component';
import { ViagemFormComponent } from './components/crud/viagem/form/form.component';
import { SascarVeiculoListComponent } from './components/crud/sascar-veiculos/list/list.component';
import { SascarVeiculoFormComponent } from './components/crud/sascar-veiculos/form/form.component';
import { InconformidadeListComponent } from './components/crud/inconformidade/list/list.component';
import { InconformidadeFormComponent } from './components/crud/inconformidade/form/form.component';
import { ContratosComponent } from './components/administrativo/contratos/contratos.component';
import { RotaListComponent } from './components/rotas/list/list.component';
import { RotaFormComponent } from './components/rotas/form/form.component';
import { NumeroViagemComponent } from './components/reports/numero-viagem/numero-viagem.component';
import { EstimativaProducaoComponent } from './components/reports/estimativa-producao/estimativa-producao.component';
import { GraficoVelocidadeComponent } from './components/reports/grafico-velocidade/grafico-velocidade.component';
import { TipoRankingListComponent } from './components/crud/tipo-ranking/list/list.component';
import { TipoRankingFormComponent } from './components/crud/tipo-ranking/form/form.component';
import { RankingMotoristaComponent } from './components/ranking-motorista/ranking-motorista.component';
import { PontuacaoAvancadaComponent } from './components/reports/pontuacao-avancada/pontuacao-avancada.component';
import { TempoFaixaComponent } from './components/reports/tempo-faixa/tempo-faixa.component';
import { DetalheTelemetriaComponent } from './components/reports/detalhe-telemetria/detalhe-telemetria.component';
import { TempoTransporteGraficoComponent } from './components/reports/tempo-transporte-grafico/tempo-transporte-grafico.component';
import { TempoCarregamentoGraficoComponent } from './components/reports/tempo-carregamento-grafico/tempo-carregamento-grafico.component';
import { SensorPortaComponent } from './components/reports/sensor-porta/sensor-porta.component';
import { ApuracaoComponent } from './components/reports/apuracao/apuracao.component';
import { MonitoramentoComponent } from './components/monitoramento/monitoramento.component';
import { TempoReferenciaComponent } from './components/reports/tempo-referencia/tempo-referencia.component';
import { TempoReferenciaDetalhesComponent } from './components/reports/tempo-referencia-detalhes/tempo-referencia-detalhes.component';
import { PercursoDetalhadoComponent } from './components/reports/percurso-detalhado/percurso-detalhado.component';
import { SensoresDetalhadoComponent } from './components/reports/sensores-detalhado/sensores-detalhado.component';
import { EventoMotoristaComponent } from './components/reports/evento-motorista/evento-motorista.component';
import { GrupoLocaisFormComponent } from './components/crud/grupo-locais/form/form.component';
import { GrupoLocaisListComponent } from './components/crud/grupo-locais/list/list.component';
import { JornadaMotoristaComponent } from './components/jornada-motorista/jornada-motorista.component';
import { MonitoramentoJornadaComponent } from './components/monitoramento-jornada/monitoramento-jornada.component';
import { IdentificacaoOperadorListComponent } from './components/crud/identificacaooperador/list/list.component';
import { IdentificacaoOperadorFormComponent } from './components/crud/identificacaooperador/form/form.component';
import { PontosPercorridosComponent } from './components/reports/pontos-percorridos/pontos-percorridos.component';
import { ProdutividadeVeiculoComponent } from './components/reports/produtividade-veiculo/produtividade-veiculo.component';
import { ProdutividadeMotoristaAdmComponent } from './components/reports/produtividade-motorista-adm/produtividade-motorista-adm.component';
import { DailyPanelDashboardComponent } from './components/daily-panel-dashboard/panel-dashboard.component';
import { NewContextPickerComponent } from './components/new-context-picker/new-context-picker.component';
import { TelaIndicadorGestaoComponent } from './components/indicadores/gestao/tela-indicador-gestao.component';
import { TelaIndicadoresComponent } from './components/indicadores/tela-indicadores.component';
import { TempoPeriodoParadoComponent } from './components/reports/tempo-periodo-parado/tempo-periodo-parado.component';
import { RegistroAlteracoesComponent } from './components/reports/registro-alteracoes/registro-alteracoes.component';
import { MetasListComponent } from './components/crud/metas/list/list.component';
import { MetasFormComponent } from './components/crud/metas/form/form.component';
import { TurnoEscalaListComponent } from './components/crud/turno-escala/list/list.component';
import { TurnoEscalaFormComponent } from './components/crud/turno-escala/form/form.component';
import { ExportacaoGradeJornadaComponent } from './components/reports/exportacao-grade-jornadas/exportacao-grade-jornada';
import { DashVersoesComponent } from './components/dash-versoes/dash-versoes.component';
import { DetalhesInconformidadesComponent } from './components/reports/detalhes-inconformidades/detalhes-inconformidades.component';
import { DetalhesInconformidadesViamComponent } from './components/reports/detalhes-inconformidades-viam/detalhes-inconformidades-viam.component';
import { TempoDirecaoComponent } from './components/reports/tempo-direcao/tempo-direcao.component';
import { DetalhesPercursoVelocidadeComponent } from './components/reports/detalhes-percurso-velocidade/detalhes-percurso-velocidade.component';
import { RelatorioSituacaoVeiculosComponent } from './components/reports/relatorio-situacao-veiculos/relatorio-situacao-veiculos.component';
import { DetalhesRastreadorPortatilComponent } from './components/reports/detalhes-rastreador-portatil/detalhes-rastreador-portatil.component';
import { RelatorioIntegracaoSllic } from './components/reports/integracao-sllic/integracao-sllic.component';
import { HoursTotalizerComponent } from './components/reports/hour-totalizer/list.component';
import { TelaIndicadoresBauComponent } from './components/indicadores/bau/tela-indicadores-bau.component';
import { ConsultaLocaisPercorridosComponent } from './components/reports/consulta-locais-percorridos/consulta-locais-percorridos.component';
import { Portaria671FormComponent } from './components/crud/portarias/671/form/form.component';
import { EspelhoPontoCompoenent } from './components/reports/espelho-ponto/espelho-ponto.component'
import { ArquivoAeJComponent } from './components/reports/arquivo-aej/arquivo-aej.component';
import { ArquivoAfdComponent } from './components/reports/arquivo-afd/arquivo-afd.component';
import { PainelIndicadoresComponent } from './components/painel-indicadores/painel-indicadores.component';
import { LiberacaoPontoComponent } from './components/liberacao-ponto/liberacao-ponto.component';
import { QuadroAlertasComponent } from './components/crud/alertas/quadro-alertas/quadro-alertas.component';
import { CadastroAvisoComponent } from './components/crud/alertas/cadastro-aviso/cadastro-aviso.component';
import { GestaoAvisosComponent } from './components/crud/alertas/gestao-avisos/gestao-avisos.component';
import { NewGradesJornadasComponent } from './components/new-grades-jornadas/new-grades-jornadas.component';
import { NewProdutividadeComponent } from './components/new-produtividade/new-produtividade.component';
import { NewTotalizadoresComponent } from './components/new-totalizadores/new-totalizadores.component';
import { NewDetalheInconformidadeComponent } from './components/new-detalhe-inconformidade/new-detalhe-inconformidade.component';
import { NewMarcacaoComponent } from './components/new-marcação/new-marcação.component';
import { NewConsultaJornadaComponent } from './components/new-consulta-jornada/new-consulta-jornada.component';
import { NewCadastroEmpresaComponent } from './components/new-cadastro-empresa/new-cadastro-empresa.component';
import { NewCadastroUnidadeComponent } from './components/new-cadastro-unidade/new-cadastro-unidade.component';

import { CorrecaoEventosComponent } from './components/administrativo/correcao-eventos/correcao-eventos.component';
import { TaskLogsComponent } from './components/administrativo/task-logs/task-logs.component';
import { DirecaoVersusPlacaComponent } from './components/reports/direcao-versus-placa/direcao-versus-placa.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'context-picker', component: NewContextPickerComponent, canActivate: [AuthGuard] },
  {
    path: 'viam',
    component: BaseContainerComponent,
    canActivate: [AuthGuard],
    children: [
      // { path: 'dash-versoes/:versao', component: DashVersoesComponent, canActivate: [AuthGuard] },
      { path: 'clocking-report', component: ClockingReportListComponent, canActivate: [AuthGuard] },
      { path: 'chatdriver-report', component: ChatDriverListComponent, canActivate: [AuthGuard] },
      { path: 'hourtotalizer-report', component: HoursTotalizerComponent, canActivate: [AuthGuard] },
      { path: 'espelho-ponto-report', component: EspelhoPontoCompoenent, canActivate: [AuthGuard] },
      { path: 'arquivo-aej-report', component: ArquivoAeJComponent, canActivate: [AuthGuard] },
      { path: 'arquivo-afd-report', component: ArquivoAfdComponent, canActivate: [AuthGuard] },
      { path: 'events-report', component: EventsListComponent, canActivate: [AuthGuard] },
      { path: 'journeylog-report', component: JourneyLogListComponent, canActivate: [AuthGuard] },
      { path: 'journeycalculation-report', component: JourneyCalculationListComponent, canActivate: [AuthGuard] },
      { path: 'journeycorrection-report', component: JourneyCorrectionListComponent, canActivate: [AuthGuard] },
      { path: 'versionpanel', component: VersionPanelComponent, canActivate: [AuthGuard] },
      { path: 'tomadadeforca', component: TomadaDeForcaComponent, canActivate: [AuthGuard] },
      { path: 'compressorcamarafria', component: CompressorCamaraFriaComponent, canActivate: [AuthGuard] },
      { path: 'disponibilidade', component: DisponibilidadeComponent, canActivate: [AuthGuard] },
      { path: 'evento', component: EventoComponent, canActivate: [AuthGuard] },
      { path: 'horimetro', component: HorimetroComponent, canActivate: [AuthGuard] },
      { path: 'percurso', component: PercursoComponent, canActivate: [AuthGuard] },
      { path: 'velocidade', component: VelocidadeComponent, canActivate: [AuthGuard] },
      { path: 'dispositivoportatil', component: DispositivoportatilComponent, canActivate: [AuthGuard] },
      { path: 'relatoriodiariamotorista', component: RelatorioDiariaMotoristaComponent, canActivate: [AuthGuard] },
      { path: 'relatoriopresencaequipamento', component: PresencaEquipamentoComponent, canActivate: [AuthGuard] },
      { path: 'relatoriotransporteequipamento', component: TransporteEquipamentoComponent, canActivate: [AuthGuard] },
      { path: 'graficoproducaoequipammento', component: GraficoProducaoEquipamentoComponent, canActivate: [AuthGuard] },
      { path: 'usobetoneira', component: UsoBetoneiraComponent, canActivate: [AuthGuard] },
      { path: 'relatorionumeroviagem', component: NumeroViagemComponent, canActivate: [AuthGuard] },
      { path: 'relatorioestimativaproducao', component: EstimativaProducaoComponent, canActivate: [AuthGuard] },
      { path: 'relatoriograficovelocidade', component: GraficoVelocidadeComponent, canActivate: [AuthGuard] },
      { path: 'relatoriotempotransportegrafico', component: TempoTransporteGraficoComponent, canActivate: [AuthGuard] },
      { path: 'relatoriotempocarregamentografico', component: TempoCarregamentoGraficoComponent, canActivate: [AuthGuard] },
      { path: 'relatoriosensorporta', component: SensorPortaComponent, canActivate: [AuthGuard] },
      { path: 'relatorioapuracao', component: ApuracaoComponent, canActivate: [AuthGuard] },
      { path: 'relatoriotemporeferencia', component: TempoReferenciaComponent, canActivate: [AuthGuard] },
      { path: 'relatoriotemporeferenciadetalhe', component: TempoReferenciaDetalhesComponent, canActivate: [AuthGuard] },
      { path: 'relatoriopercursodetalhado', component: PercursoDetalhadoComponent, canActivate: [AuthGuard] },
      { path: 'relatoriosensoreventodetalhadoo', component: SensoresDetalhadoComponent, canActivate: [AuthGuard] },
      { path: 'relatorioeventomotorista', component: EventoMotoristaComponent, canActivate: [AuthGuard] },
      { path: 'relatorioprodutividadeveiculo', component: ProdutividadeVeiculoComponent, canActivate: [AuthGuard] },
      { path: 'relatorio-grade-de-jornadas', component: ExportacaoGradeJornadaComponent, canActivate: [AuthGuard] }
    ]
  },
  {
    path: 'adm',
    component: BaseContainerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'main', component: DailyPanelDashboardComponent, canActivate: [AuthGuard] },

      { path: 'branch/list', component: BranchListComponent, canActivate: [AuthGuard] },
      { path: 'branch/form/:state/:id', component: BranchFormComponent, canActivate: [AuthGuard] },

      { path: 'color/list', component: ColorListComponent, canActivate: [AuthGuard] },

      { path: 'company/list', component: CompanyListComponent, canActivate: [AuthGuard] },
      { path: 'company/form/:state/:id', component: CompanyFormComponent, canActivate: [AuthGuard] },

      { path: 'driver/list', component: DriverListComponent, canActivate: [AuthGuard] },
      { path: 'driver/form/:state/:id', component: DriverFormComponent, canActivate: [AuthGuard] },

      { path: 'dsrgrid', component: DSRGridComponent, canActivate: [AuthGuard] },

      { path: 'holiday/list', component: HolidayListComponent, canActivate: [AuthGuard] },
      { path: 'holiday/form/:state/:id', component: HolidayFormComponent, canActivate: [AuthGuard] },

      { path: 'journey/list', component: JourneyListComponent, canActivate: [AuthGuard] },
      { path: 'journey/form', component: JourneyFormComponent, canActivate: [AuthGuard] },

      { path: 'loadingunloadingpoints/list', component: LoadingUnloadingPointListComponent, canActivate: [AuthGuard] },
      { path: 'loadingunloadingpoints/form/:state/:id', component: LoadingUnloadingPointsFormComponent, canActivate: [AuthGuard] },

      { path: 'motive/list', component: MotiveListComponent, canActivate: [AuthGuard] },
      { path: 'motive/form/:state/:id', component: MotiveFormComponent, canActivate: [AuthGuard] },

      { path: 'nonconformity', component: NonconformityComponent, canActivate: [AuthGuard] },
      { path: 'nonconformityCalendar', component: NonconformityCalendarComponent, canActivate: [AuthGuard] },

      { path: 'paramcfg/list', component: ParamcfgListComponent, canActivate: [AuthGuard] },
      { path: 'paramcfg/form/:state/:id', component: ParamcfgFormComponent, canActivate: [AuthGuard] },

      { path: 'shift/list', component: ShiftListComponent, canActivate: [AuthGuard] },
      { path: 'shift/form/:state/:id', component: ShiftFormComponent, canActivate: [AuthGuard] },

      { path: 'taxandinspectbarrier/list', component: TaxAndInspectBarrierListComponent, canActivate: [AuthGuard] },
      { path: 'taxandinspectbarrier/form/:state/:id', component: TaxAndInspectBarrierFormComponent, canActivate: [AuthGuard] },

      { path: 'tolerance/list', component: ToleranceListComponent, canActivate: [AuthGuard] },
      { path: 'tolerance/form/:state/:id', component: ToleranceFormComponent, canActivate: [AuthGuard] },

      { path: 'user/list', component: UserListComponent, canActivate: [AuthGuard] },
      { path: 'user/form/:state/:id', component: UserFormComponent, canActivate: [AuthGuard] },
      { path: 'user/password', component: ChangePasswordComponent, canActivate: [AuthGuard] },

      { path: 'vehicle/form/:state/:id', component: VehicleFormComponent, canActivate: [AuthGuard] },

      { path: 'vehicletype/list', component: VehicleTypeListComponent, canActivate: [AuthGuard] },
      { path: 'vehicletype/form/:state/:id', component: VehicleTypeFormComponent, canActivate: [AuthGuard] },

      { path: 'sascar-events', component: SascarEventsComponent, canActivate: [AuthGuard] },

      { path: 'sascar-webserver', component: SascarWebserverComponent, canActivate: [AuthGuard] },

      { path: 'sascar-config', component: SascarConfigComponent, canActivate: [AuthGuard] },

      { path: 'modelo-equipamento/list', component: ModeloEquipamentoListComponent, canActivate: [AuthGuard] },
      { path: 'modelo-equipamento/form/:state/:id', component: ModeloEquipamentoFormComponent, canActivate: [AuthGuard] },

      { path: 'modelo-veiculo/list', component: ModeloVeiculoListComponent, canActivate: [AuthGuard] },
      { path: 'modelo-veiculo/form/:state/:id', component: ModeloVeiculoFormComponent, canActivate: [AuthGuard] },

      { path: 'equipamento/list', component: EquipamentoListComponent, canActivate: [AuthGuard] },
      { path: 'equipamento/form/:state/:id', component: EquipamentoFormComponent, canActivate: [AuthGuard] },

      { path: 'local/list', component: LocalListComponent, canActivate: [AuthGuard] },
      { path: 'local/form/:state/:id', component: LocalFormComponent, canActivate: [AuthGuard] },

      { path: 'grupoLocais/list', component: GrupoLocaisListComponent, canActivate: [AuthGuard] },
      { path: 'grupoLocais/form/:state/:id', component: GrupoLocaisFormComponent, canActivate: [AuthGuard] },

      { path: 'equipamentoportatil/list', component: EquipamentoPortatilListComponent, canActivate: [AuthGuard] },
      { path: 'equipamentoportatil/form/:state/:id', component: EquipamentoPortatilFormComponent, canActivate: [AuthGuard] },

      { path: 'veiculo/list', component: VeiculoListComponent, canActivate: [AuthGuard] },
      { path: 'veiculo/form/:state/:id', component: VeiculoFormComponent, canActivate: [AuthGuard] },

      { path: 'diariamotorista/list', component: DiariaMotoristaListComponent, canActivate: [AuthGuard] },

      { path: 'operador/list', component: OperadorListComponent, canActivate: [AuthGuard] },
      { path: 'operador/form/:state/:id', component: OperadorFormComponent, canActivate: [AuthGuard] },

      { path: 'tipofuncionario/list', component: TipoFuncionarioListComponent, canActivate: [AuthGuard] },
      { path: 'tipofuncionario/form/:state/:id', component: TipoFuncionarioFormComponent, canActivate: [AuthGuard] },

      { path: 'macros/list', component: MacrosListComponent, canActivate: [AuthGuard] },
      { path: 'macros/form/:state/:id', component: MacrosFormComponent, canActivate: [AuthGuard] },

      { path: 'hodometro/form', component: HodometroFormComponent, canActivate: [AuthGuard] },

      { path: 'controlecombustivel/form', component: ControleCombustivelFormComponent, canActivate: [AuthGuard] },
      { path: 'faturamento', component: FaturamentoComponent, canActivate: [AuthGuard] },
      { path: 'rankingmotorista', component: RankingMotoristaComponent, canActivate: [AuthGuard] },
      { path: 'relatorio-pontuacao-avancada', component: PontuacaoAvancadaComponent, canActivate: [AuthGuard] },
      { path: 'relatorio-periodo-parado', component: TempoPeriodoParadoComponent, canActivate: [AuthGuard] },
      { path: 'registro-alteracoes', component: RegistroAlteracoesComponent, canActivate: [AuthGuard] },
      { path: 'relatorio-tempo-faixa', component: TempoFaixaComponent, canActivate: [AuthGuard] },
      { path: 'relatorio-detalhe-telemetria', component: DetalheTelemetriaComponent, canActivate: [AuthGuard] },
      { path: 'relatorio-detalhe-inconformidades', component: DetalhesInconformidadesComponent, canActivate: [AuthGuard] },
      { path: 'relatorio-detalhe-inconformidades-viam', component: DetalhesInconformidadesViamComponent, canActivate: [AuthGuard] },
      { path: 'contratos', component: ContratosComponent, canActivate: [AuthGuard] },
      { path: 'relatorioprodutivademotorista', component: ProdutividadeMotoristaAdmComponent, canActivate: [AuthGuard] },

      { path: 'viagem/form', component: ViagemFormComponent, canActivate: [AuthGuard] },

      { path: '', redirectTo: 'main', pathMatch: 'full' },

      { path: 'sascarveiculo/list', component: SascarVeiculoListComponent, canActivate: [AuthGuard] },
      { path: 'sascarveiculo/form/:state/:id', component: SascarVeiculoFormComponent, canActivate: [AuthGuard] },

      { path: 'inconformidade/list', component: InconformidadeListComponent, canActivate: [AuthGuard] },
      { path: 'inconformidade/form/:state/:id', component: InconformidadeFormComponent, canActivate: [AuthGuard] },

      { path: 'rota/list', component: RotaListComponent, canActivate: [AuthGuard] },
      { path: 'rota/form/:state/:id', component: RotaFormComponent, canActivate: [AuthGuard] },

      { path: 'tipo-ranking/list', component: TipoRankingListComponent, canActivate: [AuthGuard] },
      { path: 'tipo-ranking/form/:state/:id', component: TipoRankingFormComponent, canActivate: [AuthGuard] },

      { path: 'monitoramentoViaSat', component: MonitoramentoComponent, canActivate: [AuthGuard] },

      { path: 'indicadores', component: TelaIndicadoresComponent, canActivate: [AuthGuard] },

      { path: 'indicadores-bau', component: TelaIndicadoresBauComponent, canActivate: [AuthGuard] },

      { path: 'indicadores-gestao', component: TelaIndicadorGestaoComponent, canActivate: [AuthGuard] },

      { path: 'monitoramentoViaSat', component: MonitoramentoComponent, canActivate: [AuthGuard] },

      { path: 'identificacaooperador/list', component: IdentificacaoOperadorListComponent, canActivate: [AuthGuard] },
      { path: 'identificacaooperador/form/:state/:id', component: IdentificacaoOperadorFormComponent, canActivate: [AuthGuard] },

      { path: 'relatorio-pontoper-corrido', component: PontosPercorridosComponent, canActivate: [AuthGuard] },

      { path: 'jornada-motorista', component: JornadaMotoristaComponent, canActivate: [AuthGuard] },

      { path: 'monitoramento-jornada', component: MonitoramentoJornadaComponent, canActivate: [AuthGuard] },

      { path: 'metas/list', component: MetasListComponent, canActivate: [AuthGuard] },
      { path: 'metas/form/:state/:id', component: MetasFormComponent, canActivate: [AuthGuard] },

      { path: 'turno-escala/list', component: TurnoEscalaListComponent, canActivate: [AuthGuard] },
      { path: 'turno-escala/form/:state/:id', component: TurnoEscalaFormComponent, canActivate: [AuthGuard] },

      { path: 'tempo-direcao', component: TempoDirecaoComponent, canActivate: [AuthGuard] },
      { path: 'integracao-sllic', component: RelatorioIntegracaoSllic, canActivate: [AuthGuard] },
      { path: 'relatorio-percurso-velocidade', component: DetalhesPercursoVelocidadeComponent, canActivate: [AuthGuard] },
      { path: 'relatorio-situacao-veiculos', component: RelatorioSituacaoVeiculosComponent, canActivate: [AuthGuard] },
      { path: 'relatorio-rastreador-portatil', component: DetalhesRastreadorPortatilComponent, canActivate: [AuthGuard] },
      { path: 'relatorio-consulta-locais-percorridos', component: ConsultaLocaisPercorridosComponent, canActivate: [AuthGuard] },
      { path: 'painel-indicadores', component: PainelIndicadoresComponent, canActivate: [AuthGuard] },
      { path: 'liberacao-ponto', component: LiberacaoPontoComponent, canActivate: [AuthGuard] },
      { path: 'painel-indicadores', component: PainelIndicadoresComponent, canActivate: [AuthGuard] },
      { path: 'quadro-alertas', component: QuadroAlertasComponent, canActivate: [AuthGuard] },
      { path: 'criar-avisos', component: CadastroAvisoComponent, canActivate: [AuthGuard] },
      { path: 'gestao-avisos', component: GestaoAvisosComponent, canActivate: [AuthGuard] },
      { path: 'new-grades-jornadas', component: NewGradesJornadasComponent, canActivate: [AuthGuard] },
      { path: 'new-produtividade', component: NewProdutividadeComponent, canActivate: [AuthGuard] },
      { path: 'new-totalizadores', component: NewTotalizadoresComponent, canActivate: [AuthGuard] },
      { path: 'new-detalhe-inconformidade', component: NewDetalheInconformidadeComponent, canActivate: [AuthGuard] },
      { path: 'new-marcação', component: NewMarcacaoComponent, canActivate: [AuthGuard] },
      { path: 'new-consulta-jornada', component: NewConsultaJornadaComponent, canActivate: [AuthGuard] },
      { path: 'new-cadastro-empresa', component: NewCadastroEmpresaComponent, canActivate: [AuthGuard] },
      { path: 'new-cadastro-unidade', component: NewCadastroUnidadeComponent, canActivate: [AuthGuard] },
      { path: 'correcao-eventos', component: CorrecaoEventosComponent, canActivate: [AuthGuard] },
      { path: 'task-logs', component: TaskLogsComponent, canActivate: [AuthGuard] },
      { path: 'relatorio-direcao-versus-placa', component: DirecaoVersusPlacaComponent, canActivate: [AuthGuard] }
    ]
  },
  { path: '', redirectTo: 'adm', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
    ),
  ],
  exports: [
    RouterModule
  ],
  providers: [
  ]
})

export class AppRoutingModule {
}
